/**
 * @author Irakli Kandelaki
 */

/**
 * @summary Clear a timeout function, return if the value is null.
 */

export default (timeoutFunc: number | null): void => {
    if (timeoutFunc === null) return;
    window.clearTimeout(timeoutFunc);
}
