<template>
<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_222_3084)">
<rect x="0.277344" width="23" height="22" rx="3" fill="#333333"/>
<path d="M23.2706 0H0.284082C0.280361 0 0.277344 0.00288567 0.277344 0.00644531V21.9936C0.277344 21.9971 0.280361 22 0.284082 22H23.2706C23.2743 22 23.2773 21.9971 23.2773 21.9936V0.00644531C23.2773 0.00288567 23.2743 0 23.2706 0Z" fill="white"/>
<path d="M4.70005 11.2178V8.71248H6.1274C6.53249 8.71248 6.80415 8.80722 6.96689 8.97442C7.13275 9.14482 7.22296 9.39669 7.22296 9.74598V10.1843C7.22296 10.5219 7.13182 10.7732 6.96109 10.9513L6.96103 10.9514C6.79836 11.1214 6.52535 11.2178 6.11687 11.2178H4.70005ZM4.15244 7.68125H3.5625H3.4625V7.78125V14.2195V14.3195H3.5625H4.60532H4.70532V14.2195V12.254H6.14321C6.92751 12.254 7.5234 12.0805 7.90822 11.7124C8.29051 11.3468 8.47631 10.8412 8.47631 10.2094V9.72079C8.47631 9.08929 8.29064 8.58525 7.90823 8.22284C7.52676 7.85459 6.93242 7.68125 6.14847 7.68125H4.70005H4.60005H4.25244H4.15244ZM10.5818 14.3195H10.6818H11.8933C12.7999 14.3195 13.5014 14.0993 13.9795 13.6419L13.9798 13.6416C14.4575 13.1812 14.6898 12.529 14.6898 11.7006V10.2901C14.6898 9.46184 14.4576 8.81264 13.9794 8.35871C13.5012 7.90143 12.7998 7.68125 11.8933 7.68125H10.6818H10.5818H10.2553H10.1553H9.53902H9.43902V7.78125V14.2195V14.3195H9.53902H10.1606H10.2606H10.5818ZM10.6818 13.2681V8.72256H11.8564C12.3947 8.72256 12.7803 8.86247 13.0325 9.12507L13.0325 9.12507L13.0333 9.12594C13.2901 9.38692 13.4259 9.77119 13.4259 10.2951V11.6956C13.4259 12.2158 13.2903 12.6005 13.0329 12.8652L13.0325 12.8656C12.7803 13.1282 12.3947 13.2681 11.8564 13.2681H10.6818ZM15.7344 14.2195V14.3195H15.8344H16.8878H16.9878V14.2195V11.7301H19.9109H20.0109V11.6301V10.7838V10.6838H19.9109H16.9878V8.72759H19.9899H20.0899V8.62759V7.78125V7.68125H19.9899H16.9878H16.8878H16.1399H16.0399H15.8344H15.7344V7.78125V14.2195Z" fill="#726D7F" stroke="#726D7F" stroke-width="0.2"/>
</g>
<defs>
<clipPath id="clip0_222_3084">
<rect x="0.277344" width="23" height="22" rx="3" fill="white"/>
</clipPath>
</defs>
</svg>
</template>