import type { Flat, Pin } from "../../ProjectsRender/Parts/projectsRender.interface";
import type { RouteLocation } from "vue-router";
import { handleResize } from "@/composables/resizeHandler";

const { windowWidth } = handleResize();

export const getNewFullPath = (newParam: string, itemNum: number, pinPositions: Pin[],
    svgRenderType: string, locale: string, route: RouteLocation): string => {

    let id: number;
    if (windowWidth.value >= 992) {
        const matchedPinId = pinPositions.filter(
            (pin: Pin) => pin?.relatedData === itemNum
        );
        id = matchedPinId[0]?.relatedData;
    } else {
        id = itemNum;
    }

    const newParamWithHyphen = newParam + "-" + id.toString();
    const pathArray: string[] = [];

    for (let i in route.params) {
        let param = route.params[i] as string;

        if (param === newParamWithHyphen) return null;

        if (!param) break;
        if (i === "path3" && svgRenderType === "project") {
            param = newParamWithHyphen;
        } else if (i === "path4" && svgRenderType === "block") {
            param = newParamWithHyphen;
        } else if (i === "path5" && svgRenderType === "floor") {
            param = newParamWithHyphen;
        }

        pathArray.push(param);
    }
    if (!pathArray.includes(newParamWithHyphen)) {
        pathArray.push(newParamWithHyphen);
    }

    return "/" + locale + "/" + pathArray.join("/");
};



export const getNewFullPathFromRow = (item: Flat,
   locale: string, route: RouteLocation): string => {
 
    const pathArray: string[] = ['projects', route.params.path2 as string];

    const block: string = 'block-' + item?.currentBlock?.render[0]?.title;
    const floor: string = 'floor-' + item?.floor_number;
    const apNum: string = 'apartment-' + item?.flat_number;

    pathArray.push(block);
    pathArray.push(floor);
    pathArray.push(apNum);

    return "/" + locale + "/" + pathArray.join("/");
    
};

export const getPrevRoute = (route: RouteLocation, locale: string): string  => {
    const pathArray: string[] = [];

    for (let i in route.params) {
        let param = route.params[i] as string;
        if (!param) break;
        pathArray.push(param);
    }
    const newPath = pathArray.slice(0, pathArray.length-1);
    return "/" + locale + "/" + newPath.join("/");
}

