/**
 * @author Irakli Kandelaki
 */

import { useApartmentStore } from "@/store/pinia/apartments";
import type { RouteLocation } from "vue-router";

export const getNumFromParams = (param: string): string => {
    const indexOfHyphen: number = param.indexOf('-');
    return param.slice(indexOfHyphen + 1);
}


export const setDataByRoute = (ns: RouteLocation): void => {
    const apStore = useApartmentStore();
   
    const blockNumber = getNumFromParams(ns.params["path3"].slice(6) as string);
    //@ts-ignore
    apStore.blockNumber = blockNumber;
    apStore.selectedBlockNumber = apStore.blockNumber;

    if (
        ns.params["path3"].includes("block") &&
        ns.params["path4"].includes("floor") && !apStore.isApartmentOpenedFromRow
    ) {
        const numFromParams: string = getNumFromParams(ns.params["path4"] as string);
        apStore.floorNumber = Number(numFromParams);
        apStore.selectedFloorNumber = apStore.floorNumber;

    }

    if (ns.params["path5"].includes("apartment")) {
        apStore.selectedApNumber = getNumFromParams(ns.params["path5"] as string);

    }
}
